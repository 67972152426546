body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 768px) {
  // #root {
  //   height: 100%;
  //   overflow-y: auto;
  // }
}

@primary-color: rgba(76, 132, 71, 1);@second-color: rgba(255, 138, 38, 1);@bg-color: #ffffff;@bg-second-color: #f5f5f5;@link-color: #333;@success-color: rgba(82, 196, 26, 1);@warning-color: rgba(250, 173, 20, 1);@error-color: rgba(245, 34, 45, 1);@font-size-base: 16px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #333;@text-color-666: #666;@text-color-secondary: #999;@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);@default-body-width: 1380px;@layout-body-background: #f0f2f5;@layout-header-background: #fff;@layout-header-height: 120px;@layout-header-padding: 0 50px;@layout-header-color: @text-color;@layout-footer-padding: 24px 50px;@layout-footer-background: @layout-body-background;@layout-sider-background: @layout-header-background;@layout-trigger-height: 48px;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;